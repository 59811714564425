import { lazy } from "react";

// project import
import Loadable from "components/Loadable";
import MainLayout from "layout/MainLayout";
import DealersPage from "pages/extra-pages/Dealers";
import DealerAddPage from "pages/extra-pages/DealerAdd";

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import("pages/dashboard")));

// render - sample page
const SamplePage = Loadable(lazy(() => import("pages/extra-pages/SamplePage")));
const Projects = Loadable(lazy(() => import("pages/extra-pages/Projects")));
const ProjectZones = Loadable(lazy(() => import("pages/extra-pages/ProjectZones")));
const B2BFiles = Loadable(lazy(() => import("pages/extra-pages/B2bFiles")));
const B2BFileAdd = Loadable(lazy(() => import("pages/extra-pages/B2BFileAdd")));

// render - utilities
const Typography = Loadable(
  lazy(() => import("pages/components-overview/Typography"))
);
const Color = Loadable(lazy(() => import("pages/components-overview/Color")));
const Shadow = Loadable(lazy(() => import("pages/components-overview/Shadow")));
const AntIcons = Loadable(
  lazy(() => import("pages/components-overview/AntIcons"))
);

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/",
      element: <DashboardDefault />,
    },
    {
      path: "color",
      element: <Color />,
    },
    {
      path: "dashboard",
      children: [
        {
          path: "default",
          element: <DashboardDefault />,
        },
      ],
    },
    {
      path: "calculator/edit/:id",
      element: <SamplePage/>,
      role: ["4", "3"],
    },
    {
      path: "calculator",
      element: <SamplePage />,
      role: ["4", "3"],
    },
    {
      path: "dealers",
      element: <DealersPage />,
      role: ["4"],
    },
    {
      path: "projects",
      element: <Projects />,
      role: ["4"],
    },
    {
      path: "/projects/:projectId/zones",
      element: < ProjectZones/>,
      role: ["4"],
    },
    {
      path: "dealer/:id",
      element: <DealerAddPage />,
      role: ["4"],
    },
    {
      path: "dealer/add",
      element: <DealerAddPage />,
      role: ["4"],
    },
    {
      path: "dealer/edit/:id",
      element: <DealerAddPage />,
      role: ["4"],
    },
    {
      path: "shadow",
      element: <Shadow />,
    },
    {
      path: "typography",
      element: <Typography />,
    },
    {
      path: "icons/ant",
      element: <AntIcons />,
    },
    {
      path: "files",
      element: <B2BFiles/>
    },
    {
      path: "files/add",
      element: <B2BFileAdd/>,
      role: ["3"],
    }

  ],
};

export default MainRoutes;
