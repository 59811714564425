import axios from "axios";

const API_URL = process.env.REACT_APP_API_KEY;
//const API_URL = 'http://141.98.153.118:2/api';

class StrapiServices {
  async put(url, data, auth = true) {
    console.log("response");

    let headers = null;
    if (auth) {
      headers = {
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).jwt,
      };
    }
    try {
      const response = await axios.put(API_URL + url, data, {
        headers: headers,
      });
      console.log("response");
      console.log(response);
      return response.data;
    } catch (e) {
      return false;
    }
  }
  async post(url, data, auth = true) {
    console.log("response");

    let headers = null;
    if (auth) {
      headers = {
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).jwt,
      };
    }
    try {
      const response = await axios.post(API_URL + url, data, {
        headers: headers,
      });
      return response.data;
    } catch (e) {
      return false;
    }
  }

  async get(url, auth = true) {
    let headers = null;
    if (auth) {
      headers = {
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).jwt,
      };
    }
    const response = await axios.get(API_URL + url, {
      headers: headers,
    });
    return response.data;
  }
  async delete(url, auth = true) {
    let headers = null;
    if (auth) {
      headers = {
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).jwt,
      };
    }
    const response = await axios.delete(API_URL + url, {
      headers: headers,
    });
    return response.data;
  }
}
const strapiServicesInstance = new StrapiServices();
export default strapiServicesInstance;
