// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.apexcharts-legend-series .apexcharts-legend-marker {
  left: -4px !important;
  top: 2px !important;
}

.main-cl {
  color: #e85416;
}
.primary {
  color: #e85416;
}
.edit-row {
  text-decoration: none;
  font-weight: bold !important;
}
.css-1ci3ki6-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
  background-color: #fffe4b5e;
  color: #9d1652;
  -webkit-text-fill-color: #9d1652;
}

.resultTable {
  background-color: bisque;
}

.percentageResult {
  border: 2px solid green !important;
  background: #a6d46087;
}
`, "",{"version":3,"sources":["webpack://./src/assets/third-party/apex-chart.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,mBAAmB;AACrB;;AAEA;EACE,cAAc;AAChB;AACA;EACE,cAAc;AAChB;AACA;EACE,qBAAqB;EACrB,4BAA4B;AAC9B;AACA;EACE,2BAA2B;EAC3B,cAAc;EACd,gCAAgC;AAClC;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,kCAAkC;EAClC,qBAAqB;AACvB","sourcesContent":[".apexcharts-legend-series .apexcharts-legend-marker {\n  left: -4px !important;\n  top: 2px !important;\n}\n\n.main-cl {\n  color: #e85416;\n}\n.primary {\n  color: #e85416;\n}\n.edit-row {\n  text-decoration: none;\n  font-weight: bold !important;\n}\n.css-1ci3ki6-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {\n  background-color: #fffe4b5e;\n  color: #9d1652;\n  -webkit-text-fill-color: #9d1652;\n}\n\n.resultTable {\n  background-color: bisque;\n}\n\n.percentageResult {\n  border: 2px solid green !important;\n  background: #a6d46087;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
