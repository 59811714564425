// assets
import {
  ChromeOutlined,
  QuestionOutlined,
  ProfileOutlined,
  FileOutlined
} from "@ant-design/icons";

// icons
const icons = {
  ChromeOutlined,
  QuestionOutlined,
  ProfileOutlined,
  FileOutlined
};

// ==============================|| MENU ITEMS - SAMPLE PAGE & DOCUMENTATION ||============================== //

const getMenuItems = () => {
  const role = localStorage.getItem('role');

  const menuItems = [
    {
      id: "sample-page",
      title: "Calculator",
      type: "item",
      url: "/calculator",
      icon: icons.ChromeOutlined,
    },
    {
      id: "dealers",
      title: "Bayiler",
      type: "item",
      url: "/dealers",
      icon: icons.ProfileOutlined,
    },
    {
      id: "projects",
      title: "Projeler",
      type: "item",
      url: "/projects",
      icon: icons.ChromeOutlined,
    },
    {
      id: "files",
      title: "Dosyalar",
      type: "item",
      url: "/files",
      icon: icons.FileOutlined, // Dosyalar için uygun ikon
    },
  ];


  // Add the "Tüm Proje Alanları" item only if the role is equal to 3
  if (role === '3') {
    menuItems.push({
      id: "allProjectZones",
      title: "Tüm Proje Alanları",
      type: "item",
      url: "/projects/0/zones",
      icon: icons.ChromeOutlined,
    });
  }

  return {
    id: "support",
    title: "",
    type: "group",
    children: menuItems,
  };
};

const support = getMenuItems();

export default support;
