import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

// material-ui
import {
    Box,
    Button,
    Chip,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Stack,
    Typography,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";

// third party
import * as Yup from "yup";
import { Formik } from "formik";

// project import
import AnimateButton from "components/@extended/AnimateButton";
import strapiServicesInstance from "store/strapiServices";

const DealerAddPage = () => {
    const { id } = useParams(); // Get the ID from URL params
    const navigate = useNavigate();

    const [users, setUsers] = useState([]);
    const [selectedUserIds, setSelectedUserIds] = useState([]);
    const [initialValues, setInitialValues] = useState({
        name: "",
        status: true,
        users: []
    });

    const fetchUsers = async () => {
        try {
            const result = await strapiServicesInstance.get(`/users`);
            if (result) {
                setUsers(result);
            }
        } catch (error) {
            console.error("Error fetching users:", error);
        }
    };

    const fetchCustomer = async () => {
        try {
            const result = await strapiServicesInstance.get(`/customers/${id}?populate=users`);
            if (result && result.data) {
                const customer = result.data.attributes;
                setInitialValues({
                    name: customer.name,
                    status: customer.status,
                    users: customer.users.data.map(user => user.id) // Populate selectedUserIds for displaying selected users
                });
                setSelectedUserIds(customer.users.data.map(user => user.id)); // For displaying in the UI
            }
        } catch (error) {
            console.error("Error fetching customer:", error);
        }
    };

    const handleUserAdd = (userId) => {
        if (!selectedUserIds.includes(userId)) {
            setSelectedUserIds([...selectedUserIds, userId]);
        }
    };

    const handleUserDelete = (userId) => {
        setSelectedUserIds(selectedUserIds.filter(id => id !== userId));
    };

    useEffect(() => {
        fetchUsers();
        if (id) {
            fetchCustomer();
        }
    }, [id]);

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={Yup.object().shape({
                    name: Yup.string().max(255).required("Name is required"),
                    status: Yup.boolean().required("Status is required"),
                    users: Yup.array().of(Yup.number()).required("User is required"),
                })}
                enableReinitialize
                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                    try {
                        const payload = {
                            name: values.name,
                            status: values.status,
                            users: selectedUserIds,
                        };

                        if (id) {
                            // PUT isteği (güncelleme)
                            await strapiServicesInstance.put(`/customers/${id}`, { data: payload });
                        } else {
                            // POST isteği (yeni kayıt)
                            await strapiServicesInstance.post('/customers', { data: payload });
                        }
                        setStatus({ success: true });
                        setSubmitting(false);
                        navigate("/dealers");
                    } catch (err) {
                        console.error(err);
                        setStatus({ success: false });
                        setErrors({ submit: err.message });
                        setSubmitting(false);
                    }
                }}
            >
                {({
                      errors,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      touched,
                      values,
                  }) => (
                    <form noValidate onSubmit={handleSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <Stack spacing={1}>
                                    <InputLabel htmlFor="name-signup">Bayi Adı*</InputLabel>
                                    <FormControl fullWidth>
                                        <OutlinedInput
                                            id="name-signup"
                                            type="text"
                                            value={values.name}
                                            name="name"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            placeholder="Enter name"
                                            fullWidth
                                            disabled={Boolean(id)}
                                            error={Boolean(touched.name && errors.name)}
                                        />
                                        {touched.name && errors.name && (
                                            <FormHelperText error id="helper-text-name-signup">
                                                {errors.name}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Stack spacing={1}>
                                    <InputLabel htmlFor="status-signup">Durum*</InputLabel>
                                    <Select
                                        id="status-signup"
                                        name="status"
                                        value={values.status}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        fullWidth
                                        error={Boolean(touched.status && errors.status)}
                                    >
                                        <MenuItem value={true}>Active</MenuItem>
                                        <MenuItem value={false}>Inactive</MenuItem>
                                    </Select>
                                    {touched.status && errors.status && (
                                        <FormHelperText error id="helper-text-status-signup">
                                            {errors.status}
                                        </FormHelperText>
                                    )}
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack spacing={1}>
                                    <InputLabel htmlFor="users-signup">Kullanıcılar*</InputLabel>
                                    <Select
                                        id="users-signup"
                                        name="users"
                                        value={values.users}
                                        onBlur={handleBlur}
                                        onChange={(e) => handleUserAdd(e.target.value)}
                                        fullWidth
                                        error={Boolean(touched.users && errors.users)}
                                    >
                                        {users.length > 0 ? (
                                            users.map((user) => (
                                                <MenuItem key={user.id} value={user.id}>
                                                    {user.username}
                                                </MenuItem>
                                            ))
                                        ) : (
                                            <MenuItem disabled>No users available</MenuItem>
                                        )}
                                    </Select>
                                    {touched.users && errors.users && (
                                        <FormHelperText error id="helper-text-users-signup">
                                            {errors.users}
                                        </FormHelperText>
                                    )}
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack spacing={1}>
                                    {selectedUserIds.length > 0 && (
                                        <InputLabel>Eklenen Kullanıcılar</InputLabel>
                                    )}
                                    <Box>
                                        {selectedUserIds.map((userId) => {
                                            const user = users.find(u => u.id === userId);
                                            return (
                                                <Chip
                                                    key={userId}
                                                    label={user.username}
                                                    onDelete={() => handleUserDelete(userId)}
                                                    deleteIcon={<CloseOutlined />}
                                                    style={{ margin: '4px' }}
                                                />
                                            );
                                        })}
                                    </Box>
                                </Stack>
                            </Grid>
                            {errors.submit && (
                                <Grid item xs={12}>
                                    <FormHelperText error>{errors.submit}</FormHelperText>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <AnimateButton>
                                    <Button
                                        disableElevation
                                        disabled={isSubmitting}
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                    >
                                        {id ? "Bayi Güncelle" : "Bayi Oluştur"}
                                    </Button>
                                </AnimateButton>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Formik>
        </>
    );
};

export default DealerAddPage;
