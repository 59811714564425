import { Link } from "react-router-dom";
import {
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Tooltip,
  IconButton,
} from "../../../node_modules/@mui/material/index";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import { useEffect, useState } from "react";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import strapiServicesInstance from "store/strapiServices";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.red,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

// function createData(name, calories, fat, carbs, protein) {
//   return { name, calories, fat, carbs, protein };
// }

// const rows = [
//   createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
//   createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
//   createData("Eclair", 262, 16.0, 24, 6.0),
//   createData("Cupcake", 305, 3.7, 67, 4.3),
//   createData("Gingerbread", 356, 16.0, 49, 3.9),
// ];

const DealersPage = () => {
  const [page, setPage] = useState(0);
  const [language, setLanguage] = useState("tr");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [length, setlength] = useState(100);
  const [rows, setRows] = useState([]);
  const [filter, setFilter] = useState("all");

  const fetchData = async () => {
    try {
      let filters = "";
      if (filter !== "all") {
        filters = `filters[status]=${filter}&`;
      }
      const result = await strapiServicesInstance.get(
        `/customers?${filters}locale=${language}`
      );

      if (result.data.length) {
        setRows(result.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Link to={`/dealer/add`}>
          <Button variant="outlined" color="warning" style={{ float: "right" }}>
            <AutoFixHighIcon /> YENİ BAYİ EKLE
          </Button>
        </Link>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Bayi Adı</StyledTableCell>
                <StyledTableCell align="right">Durum</StyledTableCell>
                <StyledTableCell align="right">İşlem</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <StyledTableRow key={row.name}>
                  <StyledTableCell component="th" scope="row">
                    <Link to={`/dealer/add`} className="edit-row">
                      {row.attributes.name}
                    </Link>
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.attributes.status ? "Aktif" : "Pasif"}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <Tooltip title="Kullanıcı Ekle">
                      <Link to={`/dealer/edit/${row.id}`}>
                        <IconButton color="secondary" aria-label="add an alarm">
                          <AddCircleOutlineIcon color="success" />
                        </IconButton>
                      </Link>
                    </Tooltip>
                    <Tooltip title="Bayi Sil">
                      <Link to={`/payment-players?id=${row.id}`}>
                        <IconButton color="secondary" aria-label="add an alarm">
                          <DeleteSweepIcon color="error" />
                        </IconButton>
                      </Link>
                    </Tooltip>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          // count={rows.length}
          count={length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Grid>
    </Grid>
  );
};

export default DealersPage;
